<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Previous Due
            <v-spacer></v-spacer>
            <add-button
              v-if="unverify === 0 && !alreadyJournal && totalAmount > 0"
              :permission="'journal-create'"
              @action="reasonDialog = true"
            >
              Create Journal
            </add-button>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs4 sm4>
                <v-select
                  :disabled="gradeLoading"
                  :loading="gradeLoading"
                  :items="grades"
                  class="pa-0"
                  label="Grade"
                  v-model="grade"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs4 sm4>
                <v-select
                  :disabled="!grade"
                  :items="sections"
                  class="pa-0"
                  label="Section"
                  v-model="section"
                  outlined
                  dense
                />
              </v-flex>
              <!--<v-flex xs4 sm4>-->
              <!--<v-text-field :disabled="!grade" v-model="enrollCode" class="pa-0"-->
              <!--label="Enroll Code"></v-text-field>-->
              <!--</v-flex>-->
              <v-flex xs4 sm4>
                <v-select
                  :items="statuses"
                  class="pa-0"
                  label="Status"
                  v-model="status"
                  outlined
                  dense
                />
              </v-flex>
            </v-card-title>
          </v-card>
          <v-container v-if="!alreadyJournal">
            <strong v-if="createJournal" style="color:green; font-size: 12px;">
              <v-icon color="green" style="font-size: 17px;"
                >check_circle</v-icon
              >
              Ready for journal.
            </strong>
            <strong v-else style="color:#ff5252;font-size: 12px;">
              <v-icon color="#ff5252" style="font-size: 17px;">warning</v-icon>
              Please verify all the previous dues to create journal.
            </strong>
          </v-container>
          <v-container v-else>
            <strong style="color:green; font-size: 12px;">
              <v-icon color="green" style="font-size: 17px;"
                >check_circle</v-icon
              >
              The following data has already sent to journal.
            </strong>
          </v-container>

          <div class="data-represent">
            <div>
              <span class="d-success"></span> &nbsp;
              <strong>{{ verify }}</strong>
              <small> Verified</small>
            </div>
            <div>
              <span class="d-warning"></span> &nbsp;
              <strong>{{ unverify }}</strong>
              <small> Unverified</small>
            </div>
            <div>
              <span class="d-primary"></span> &nbsp;
              <strong>{{ journalCount }}</strong>
              <small> Journal Created</small>
            </div>

            <div>
              <span class="d-error"></span>
              <small> Total Due:</small> &nbsp;
              <strong>{{ totalAmount.currency() }}</strong>
            </div>
          </div>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :loading="form.loading"
            footer-props.items-per-page-options="rowsPerPageItems"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td class="text-xs-left">{{ index + 1 }}</td>
                <td class="text-xs-left">{{ item.enroll_code }}</td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">
                  {{ item.grade }}<sup>"{{ item.section }}"</sup>
                </td>
                <td class="text-xs-left">
                  {{ item.amount.numberFormat() }}
                </td>
                <td class="text-right">
                  <span v-if="item.stat == 2 || item.stat == 3">
                    <!--Journal created-->
                    <v-chip color="primary" outlined>Journal Created</v-chip>
                    <!--<edit-button permission="fee-update" @agree=""/>-->
                  </span>
                  <span v-else-if="item.stat == 1">
                    <v-tooltip left color="green">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="#47880b"
                          style="font-size: 35px"
                          v-on="on"
                          >check_circle</v-icon
                        >
                      </template>
                      <span>Verfied</span>
                    </v-tooltip>
                  </span>
                  <span v-else>
                    <v-tooltip left v-if="true && item.amount" color="warning">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="verifyPreviousDue(item)"
                          permission="fee-update"
                          small
                          icon
                          color="warning"
                          class="pa-0 ma-1"
                          slot="activator"
                        >
                          <v-icon small v-on="on">{{ "verified_user" }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Verify Previous Due</span>
                    </v-tooltip>
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="dialog" persistent max-width="300">
        <v-card>
          <v-card-title class="title pa-3">
            <v-text-field
              autocomplete="off"
              label="Enroll Code"
              required
              class="pa-0 pt-3"
              v-model="form.enroll_code"
            />
            <v-text-field
              autocomplete="off"
              label="Amount"
              type="number"
              required
              class="pa-0 pt-3"
              v-model="form.amount"
            />
            <v-checkbox color="blue" v-model="form.stat">
              <template v-slot:label>
                <div style="color:#333;">
                  Check to confirm
                  <p style="line-height: 10px">
                    <small style="color:#666;font-size: 10px;"
                      >Once cheked, This cannot be edited.
                    </small>
                  </p>
                </div>
              </template>
            </v-checkbox>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="dialog = false"
              >Cancel</v-btn
            >
            <v-btn
              :disabled="!form.amount"
              color="warning"
              outlined
              small
              @click="save"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="reasonDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3">
            <p style="font-size: 15px">
              Are you sure you want to post on account?
            </p>
            <v-text-field
              autocomplete="off"
              label="Amount"
              required
              class="pa-0 pt-3"
              v-model="totalAmount"
              :disabled="true"
            />
            <!--<v-textarea outlined name="input-7-4" label="Narration" v-model="narration"></v-textarea>-->
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="reasonDialog = false"
              >Cancel</v-btn
            >
            <v-btn color="warning" outlined small @click="createToJournal"
              >Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    gradeLoading: false,
    form: new Form(
      {
        amount: "",
        enroll_code: "",
        stat: 0,
      },
      "/api/previous-due-all"
    ),
    pagination: {
      // itemsPerPage: 25,
      rowsPerPage: 1000,
    },
    previousData: [],
    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      {
        text: "Enroll Code",
        align: "left",
        value: "enroll_code",
        sortable: false,
      },
      { text: "Name", align: "left", value: "name", sortable: false },
      { text: "Grade", align: "left", value: "grade", sortable: false },
      { text: "Amount", align: "left", value: "amount", sortable: false },
      { text: "Action", align: "right", value: "action", sortable: false },
    ],
    grades: [],
    grade: "",
    enrollCode: "",
    sections: [],
    section: "",
    dialog: false,
    verify: 0,
    unverify: 0,
    journalCount: 0,
    createJournal: false,
    alreadyJournal: false,
    totalAmount: 0,
    accountServiceStatus: false,
    reasonDialog: false,
    narration: "",
    update: false,
    totalData: {
      verify: 0,
      unverify: 0,
      journalCount: 0,
      amount: 0,
    },
    statuses: [
      { value: "all", text: "All" },
      { value: "0", text: "Unverified" },
      { value: "1", text: "Verified" },
      { value: "2", text: "Journal" },
    ],
    status: "",
  }),

  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    this.getGrades();

    if (this.$auth.getServices("ACCOUNT")) this.accountServiceStatus = true;
    else this.accountServiceStatus = false;
    // this.get();
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },

    grade: function(value) {
      (this.section = ""), this.getSection();
      this.form.items.data = [];
      this.get();
    },
    section: function(value) {
      this.month = "";
      this.generate = false;
      this.print = false;
      this.form.items.data = [];
      this.get();
    },
    status: function() {
      this.get();
    },
  },

  methods: {
    get(params) {
      let extraParams =
        "gradeId=" +
        this.grade +
        "&sectionId=" +
        this.section +
        "&enrollCode=" +
        this.enrollCode.trim() +
        "&search=" +
        this.status;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      this.form.get(null, query).then(({ data }) => {
        if (data.data.length > 0) {
          this.totalAmount = parseInt(data.totalAmount.totalAmount);
          if (["", NaN, undefined, null].includes(this.totalAmount))
            this.totalAmount = 0;

          this.verify = data.count.verify ? parseInt(data.count.verify) : 0;
          this.unverify = data.count.unverify
            ? parseInt(data.count.unverify)
            : 0;
          this.journalCount = data.count.journalCount
            ? parseInt(data.count.journalCount)
            : 0;

          // if (data.data[0].previous_due.stat == 2) {
          //     this.alreadyJournal = true
          //     this.verify = data.meta.total;
          //     this.unverify = 0;
          // }
          // else this.alreadyJournal = false;
        } else {
          this.verify = 0;
          this.unverify = 0;
        }
        // this.pagination.totalItems = data.meta.total;

        this.totalData.verify = 0;
        this.totalData.unverify = 0;
        this.totalData.journalCount = 0;
        this.totalData.amount = 0;
        data.data.map((res) => {
          if (res.stat == "0") this.totalData.unverify += 1;
          if (res.stat == "1") this.totalData.verify += 1;
          if (res.stat == "2") this.totalData.journalCount += 1;
          if (res.stat != "0") this.totalData.amount += res.amount;
        });

        if (this.verify + this.totalData.journalCount == data.meta.total)
          this.createJournal = true;
        else this.createJournal = false;

        if (this.journalCount === data.meta.total) this.alreadyJournal = true;
        else this.alreadyJournal = false;
      });
    },

    save() {
      this.form.stat = this.form.stat ? 1 : 0;
      this.form
        .store()
        .then(({ data }) => {
          this.dialog = false;
          this.get();
        })
        .catch((e) => {
          if (e.data.code == 404) {
            this.$events.fire("notification", {
              message: "Record not found",
              status: "error",
            });
          }
        });
    },

    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get("/api/grades?rowsPerPage=25&sortBy=rank&descending=false")
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    singlePrint(enCode) {
      let url =
        "?billing=" +
        this.billing +
        "&billMonth=" +
        this.month +
        "&enrollCode=" +
        enCode;
      this.$rest.get("/api/download/bill/single").then(({ data }) => {
        window.open(data.data.download_url + url);
      });
    },
    getSection() {
      if (this.grade) {
        this.$rest.get("/api/section?grade=" + this.grade).then(({ data }) => {
          this.sections = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
      }
    },
    editData(r) {
      this.dialog = true;
      this.form.id = r.previous_due.id;
      this.form.stat = r.previous_due.stat == 1 ? true : false;
      this.form.amount = r.previous_due.amount;
      this.form.enroll_code = r.enroll_code;
    },
    createToJournal() {
      this.$rest
        .post("/api/journal-entry/previous-due", {
          amount: this.totalAmount,
          account_service_status: this.accountServiceStatus,
          // narration: this.narration,
        })
        .then(({ data }) => {
          this.$events.fire("notification", {
            message: data.message,
            status: "success",
          });
          this.reasonDialog = false;
          this.get();
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    verifyPreviousDue(item) {
      this.$rest
        .put("/api/previous-due-verify/" + item.enroll_id, {})
        .then((res) => {
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
          this.get();
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
    },
    savePreviousDue(item) {
      let dataToSend = [];
      dataToSend.push(item);
      this.$rest
        .post("/api/previous-due", {
          previous_dues: dataToSend,
        })
        .then((res) => {
          this.$events.fire("notification", {
            message: res.data.message,
            status: "success",
          });
          this.get();
          // console.log(res);
        })
        .catch((e) => {
          this.$events.fire("notification", {
            message: e.response.data.message,
            status: "error",
          });
        });
      // console.log(this.form.items.data);
      // console.log(item)
    },
  },
};
</script>
<style lang="scss">
.verified {
  background: #47880b !important;
  color: white !important;
  padding: 5px 10px 5px 5px !important;
  border-radius: 5px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.total {
  background: red !important;
  color: white !important;
  padding: 5px 10px 5px 5px !important;
  border-radius: 5px !important;
}

.unverified {
  background: #fb8c00 !important;
  color: white !important;
  padding: 5px 10px 5px 5px !important;
  border-radius: 5px !important;
}

.inputType {
  border: 1px solid #bbb;
  text-align: center;
  height: 34px;
  width: 86px;
}

.inputType:disabled {
  cursor: not-allowed;
  background: #eee;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
